
import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue';
import { ContentLoader } from 'vue-content-loader';
// import DeletarNcmModal from "./components/DeletarNcmModal.vue";
import ReferenciaNCM from "./components/tables/ReferenciaNCM.vue";
import ReferenciaPercentualNCM from "./components/tables/ReferenciaPercentualNCM.vue";
import ReferenciaExcecaoPercentualNCM from "./components/tables/ReferenciaExcecaoPercentualNCM.vue";
import { useRouter, useRoute } from 'vue-router';

export default defineComponent({
  name: "calculadora-precos-config",

  components: {
    // DeletarNcmModal,
    ReferenciaNCM,
    ReferenciaPercentualNCM,
    ReferenciaExcecaoPercentualNCM,
    ContentLoader
  },

  setup() {
    const router = useRouter();

    function goTo(route){       
      router.push(route);
    }

    return { goTo };
  }
});
