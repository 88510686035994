
import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue';
import { ContentLoader } from 'vue-content-loader';
import { useRouter } from 'vue-router';
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import useEmitter from '@/composables/Emmiter';
import useAlert from "@/composables/Alert";
import { getReferenciaPercentualNCM } from "@/services/CalculadoraPrecosService";

export default defineComponent({
  name: "calculadora-precos-config",

  components: {
    ContentLoader
  },

  setup() {
    const emitter = useEmitter();
    const codConcessionaria = ref(0);
    const { showTimeAlert } = useAlert();
    let postData: any = ref({});

    onMounted(async ()=>{     
      await listaProdutosNCM();
	  });

    const data = reactive({
      info: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 1,
        last_page: 7,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: 25,
        prev_page_url: null,
        to: 0,
        total: 0
      },
    });

    const loadingTable = ref(true);
    const produtosNCM: any = ref([]);
    const animate = ref(true);
    const fastFilter: any = ref("");

    async function listaProdutosNCM() {
      animate.value = true
      loadingTable.value = true;

      try{
        let response = await getReferenciaPercentualNCM();
        produtosNCM.value = response;
        trataInfo(1);
      } catch (e) {
        showTimeAlert('Não foi possível completar a solicitação', "error");
      } finally {
        loadingTable.value = false;
        animate.value = false;
      }
    }

    const loadingButton: any = ref(false)

    const router = useRouter();

    function goTo(url) {
      router.push(url);
    }

    const delayTimer = ref();

    function filterData() {
      clearTimeout(delayTimer.value);
      delayTimer.value = setTimeout(() => {
        trataInfo(1, true);
      }, 300);
    }

    function trataInfo(page = 1, filter = false){
      let baseDadosNCMProdutos = [];

      if(filter && fastFilter.value){
        baseDadosNCMProdutos = produtosNCM.value.filter(produto => produto.cod_ncm.replaceAll(".", "").includes(fastFilter.value.replaceAll(".", "")) )
      } else {
        baseDadosNCMProdutos = produtosNCM.value;
      }

      data.info.total = baseDadosNCMProdutos.length;
      data.info.last_page = Math.ceil((baseDadosNCMProdutos.length / data.info.per_page)) ?? 0;

      data.info.current_page = page;
      const start = data.info.per_page * (page - 1);
      const end =  data.info.per_page * (page);
      if(data.info.last_page == page){
        data.info.to = baseDadosNCMProdutos.length;
      } else if(end > baseDadosNCMProdutos.length){
        data.info.to = baseDadosNCMProdutos.length;
      } else {
        data.info.to = end;
      }

      data.info.data = baseDadosNCMProdutos.slice(start, end);
    }

    const servicoEspecifico: any = ref({});

    function setServicoEspecifico(servico) {
      servicoEspecifico.value = servico
    }

    return {
      animate,
      loadingButton,
      ptBr,
      ...toRefs(data),
      loadingTable,
      trataInfo,
      fastFilter,
      filterData,
      setServicoEspecifico,
      servicoEspecifico
    };
  }
});
